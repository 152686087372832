import PropTypes from "prop-types"
import React from "react"

interface Props {
  percentageNumber: number,
  children?: any
}

const ProgressBar = ({ percentageNumber, children }: Props) => {
    
    return (
        <div className="progress-bar">
            <div className="progress-bar__bar" style={{ width: `${percentageNumber}%` }}></div>
            { children && children }
        </div>
    )
}

ProgressBar.propTypes = {
  percentageNumber: PropTypes.number
}

ProgressBar.defaultProps = {
    percentageNumber: 0
}

export default ProgressBar

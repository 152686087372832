import React, { useRef, useEffect, useState } from "react"
import vw from '../utils/vw';
import { Helmet } from "react-helmet";

interface Props {
    shape: any
    id: string
}

const Shape = ({ shape, id }: Props) => {
    const { responsive, image } = shape;
    let className = '';

    if (responsive['768']['top']) {
        className = 'top-';
    } else {
        className = 'bottom-';
    }
    if (responsive['768']['left']) {
        className += 'left';
    }else {
        className += 'right';
    }
    const renderStyles = () => {
        const stylesArray: any = [];
        Object.keys(responsive).forEach((key) => {
            if (key === "0") {
                stylesArray.push(`#shape-${id} {`);
                Object.keys(responsive[key]).forEach((property) => {
                    if (property === 'transform') {
                        stylesArray.push(`} #shape-${id} .shape__image {`);
                        stylesArray.push(`${property}:${responsive[key][property]};`);
                        stylesArray.push('}{');
                    } else {
                        stylesArray.push(`${property}:${responsive[key][property]};`);
                    }
                });
                stylesArray.push('}');
            } else {
                stylesArray.push(`@media (min-width: ${key}px) {`);
                stylesArray.push(`#shape-${id} {`);
                Object.keys(responsive[key]).forEach((property) => {
                    if (property === 'transform') {
                        stylesArray.push(`} #shape-${id} .shape__image {`);
                        stylesArray.push(`${property}:${responsive[key][property]};`);
                        stylesArray.push('}{');
                    } else {
                        stylesArray.push(`${property}:${responsive[key][property]};`);
                    }
                })
                stylesArray.push('}');
                stylesArray.push('}');
            }
        });
        return stylesArray.join('');
    }
    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`${renderStyles()}`}
                </style>
            </Helmet>
            <div id={`shape-${id}`} className={ `shape ${className}` }>
                <img className="shape__image" src={`/questions/${image}`} alt={image} />
            </div>
        </>);

}


export default Shape

import React, { useRef, useEffect } from "react"
import { QuestionType, QuestionOptionType } from '../types/question-types';


interface Props {
    data: QuestionType
    onAnimationOutCompleted: (option?: QuestionOptionType, area?: string) => void
    onOptionSelected: (option?: QuestionOptionType, area?: string) => void
}

const Question = ({ data, onOptionSelected, onAnimationOutCompleted }: Props) => {
    const { question, area, options, selected } = data;
    const questionRef = useRef(null);

    const handleClickOption = async (option:QuestionOptionType) => {
        onOptionSelected( option, area );
        questionRef.current.classList.add('question--out');
        if(document.activeElement.toString() == '[object HTMLButtonElement]'){ (document.activeElement as HTMLButtonElement).blur(); }

        document.documentElement.classList.remove('ready');
        document.documentElement.classList.add('out');
        setTimeout(() => {
            onAnimationOutCompleted(option, area);            
            document.documentElement.classList.remove('out');
        }, 500);
    }
    useEffect(() => {
        if(document.activeElement.toString() == '[object HTMLButtonElement]'){ (document.activeElement as HTMLButtonElement).blur(); }
    },[])
    
    if (question && options ){
        return (
            <div ref={questionRef} className="question question--with-animation">
                <div className="question__header">
                    <h2 className="question__title">{ question }</h2>
                    <div className="question__line question__line-center"></div>            
                </div>
                <ul className="question__options">
                    { options.map(function(option, index) {
                        return (
                            <li className="question__item" key={`option-${index}`}>
                                <button className={`question__option ${ (selected && selected.score === option.score) ? 'selected' : '' }`} onClick={ () => handleClickOption(option) } dangerouslySetInnerHTML={{ __html: option.option}}></button>
                            </li>
                        );
                    })}     
                </ul>
                <div className="question__line question__line-top"></div>
                <div className="question__line question__line-left"></div>
                <div className="question__line question__line-right"></div>
                <div className="question__line question__line-bottom"></div>
            </div>
        )
    } else{
        return <></>
    }
}

export default Question

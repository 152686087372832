import React, { useState, useContext, useEffect } from "react"
import TransitionLink from '../components/transitionLink';
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShapesJSON from "../data/shapes.json"
import Question from '../components/question'
import ProgressBar from '../components/progress-bar'
import { navigate } from "gatsby"
import { QuestionOptionType } from '../types/question-types';
import Shape from '../components/shape';
import Logo from '../images/adobe-logo.inline.svg';
import { nextQuestionIndex } from '../actions/quiz';
import createjs from 'preload-js';
import FooterPolicies from '../components/footer-policies';

import {
    GlobalDispatchContext,
    GlobalStateContext,
  } from "../context/GlobalContextProvider"
import { Helmet } from "react-helmet"

interface Props {
    location: any
    pageContext: Context
    children: any
    transitionStatus: string
    entry: any
    exit: any
}
type Context = {
    settings: any
    langCode: string
    lang: string
    route: string,
    translation: any
    questionsJson: any
    question: any
    title: string
    slug: number
    shapes?: any
    options: QuestionOptionType[]
    area?: string
}

const QuestionPage = ({ pageContext, location }: Props) => {
    const { title, options, slug, area, translation, route, questionsJson, lang, langCode, settings, question } = pageContext;
    const [shuffledOptions, setShuffledOptions] = useState(options);
    const totalQuestions = questionsJson.length;
    const previousLink = slug > 1 ? `${route}question/${slug - 1}` : route;
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const { started, questions, questionsSort } = state;

    const [ shapes, setShapes ] = useState(null);
    
    const getPercentage = (pageSlug: number) => {
        return (pageSlug - 1 ) > 0 ? Math.round((pageSlug - 1 )/totalQuestions * 1000)/10 : 0
    }
    const [ percentage , setPercentage ] = useState(getPercentage(slug));
    
    const animateOut = () => {
        updateProgressBar();
    }

    const updateProgressBar = () => {
        const nextPage = slug + 1;
        setPercentage(getPercentage(nextPage));
        if (questions[slug]) {
            setTimeout(() => {
                if (document.querySelector('.question-page')) {
                    document.querySelector('.question-page').classList.add(`question-page--${questions[slug].area.toLowerCase()}`);
                }
            },500);
        }
    };
    
    const respondToQuestion = (option: QuestionOptionType, area: string) => {
        const pageNumber = slug + 1;
        dispatch({
            type: 'UPDATE_CURRENT_QUESTION',
            currentQuestion: slug
        });
        option.area = area;
        dispatch({
            type: 'SAVE_ANSWER',
            currentQuestion: slug,
            value: option
        });
        if (pageNumber <= totalQuestions) {
            document.documentElement.classList.remove('in');
            setTimeout(() => {
                navigate(
                    `${route}question/${pageNumber}`
                )
            }, 600);
        } else {
            document.documentElement.classList.remove('in');
            document.body.classList.add('final-page');
            setTimeout(() => {
                navigate(`${route}final`)
            }, 600);
        }
    }
    useEffect(() => {
        if (question && questionsSort) {
            const mappedOptions = questionsSort[slug-1].options.map((option:any) => {
                return question.options.find((item:any) => {
                    return option.score === item.score;
                });
            });
            setShuffledOptions(mappedOptions);
        }
        if (state.answers && Object.keys(state.answers).length === 15) {
            // document.body.classList.remove('in');
            // document.body.classList.add('final-page');
            
            // setTimeout(() => {
            //     navigate('/final');
            // }, 1200);
        } else {
            const nextIndex = nextQuestionIndex(state.answers);
            if (slug > nextIndex) {
                navigate(
                    `${route}question/${nextIndex}`
                )
            }
        }
    }, [state, setShuffledOptions]);
    useEffect(() => {
        if (!started) {
            if (typeof window !== 'undefined') {
                window.location.href = route;
            }
        }
    }, [started]);

    useEffect(() => {
        if (ShapesJSON[`q${slug}`]) {
            setShapes(ShapesJSON[`q${slug}`]);
        }
    },[setShapes]);

    const handleProgress = (data:any) => {
        // console.log(data.progress)
    }
    const handleComplete = (data:any) => {
        document.documentElement.classList.add('in');
    }
    useEffect(() => {
        if (shapes) {
            const preload = new createjs.LoadQueue();
            Object.keys(shapes).map((shape) => {
                preload.loadFile('/questions/'+shapes[shape].image);
            });
            preload.addEventListener("progress", handleProgress);
            preload.addEventListener("complete", handleComplete);
            preload.load();
        }        
    },[shapes])
    
    const FooterContent = () => (<>
        <TransitionLink to={ previousLink } className="link footer__link-left"><span>{ translation.general.back }</span></TransitionLink>
        <ProgressBar percentageNumber={ percentage }>
            <FooterPolicies translation={translation}/>
        </ProgressBar>
        <a href="https://adobe.com" target="_blank" className="footer__logo" aria-label={ translation.general.go_to_adobe_website }><Logo/></a>
    </>);
    const renderShapes = () => {
        if (!shapes) {
            return <></>;
        }
        return Object.keys(shapes).map((shape) => { return (<Shape key={`shape-${parseInt(shape) + 1}`} shape={ shapes[shape] } id={ `${parseInt(shape) + 1}`}  />)});
    }
    if (!started) {
        return <></>
    }
    return (
        <>
            <Layout pathname={ location.pathname} socialLinks={settings.social_media}  lang={ lang } route={route} translation={translation} slug={`question/${slug}`} about={ true } share={ false } footerContent={ FooterContent }>
                <Helmet bodyAttributes={{ class: `question-page-body ${area.toLowerCase()} lang-${lang}` }}/>
                <SEO lang={langCode} title={ title } />
                <div className={ `question-page question-page--${area?.toLocaleLowerCase()}` }>
                    <div className="container">
                        <div className="question-page__content">
                        {  questions && renderShapes() }
                            <Question data= { {
                                number: slug,
                                question: title,
                                options: shuffledOptions,
                                area: area,
                                selected: (state.answers && state.answers[`q${slug}`]) ? state.answers[`q${slug}`] : null
                            }} onAnimationOutCompleted = { (option,area) => respondToQuestion(option, area) } onOptionSelected = { () => animateOut() } />                        
                        
                        </div>
                    </div>
                </div>
            </Layout>
    </>
    )
}

export default QuestionPage
